export function fixSelectizeKeyboard(selectize, config)
{
    const defaultKeyDown = selectize.onKeyDown.bind(selectize);
    const multiple = config.multiple;

    selectize.onKeyDown = function (e) {
        const selectize = this;
        //console.debug("selectize", e, selectize);

        // 8 - backspace
        if (8 === e.keyCode) {
            // We have to manually handle backspace, to avoid bug in Selectize in Firefox.
            // (bug: backspace press in input behaves as pressing "Back" button in browser)
            // @see https://gitlab.com/sio-ag/sio-core2/core2/issues/1271

            const inQueryingMode = selectize.lastQuery !== "" && selectize.lastQuery;

            if (!inQueryingMode) {
                if (!multiple) {
                    selectize.clear();
                } else {
                    selectize.deleteSelection(e);
                }

                e.preventDefault();
            } else {
                defaultKeyDown(e);
            }

            return;
        }

        defaultKeyDown(e);

        // 13 - enter
        if (13 === e.keyCode) {
            // Focus next element if we made choice with enter
            const $canfocus = $('input');
            let index = $canfocus.index(this.$control_input) + 1;
            if (index < $canfocus.length) {
                $canfocus.eq(index).focus();
            }

            // Need to prevent default event, otherwise form submit is triggered.
            e.preventDefault();
        }
    }.bind(selectize);
}
