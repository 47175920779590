import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {BindingSignaler} from "aurelia-templating-resources";
import './seat-assignment.less'
@inject(
    Client,
    DialogController,
    BindingSignaler
)
export class SeatAssignmentChoiceForm {

    value;
    loading = true;
    seatIdentifier = '';

    constructor(
        client,
        controller,
        signaler,
    ) {
        this.client = client;
        this.controller = controller;
        this.signaler = signaler;
    }

    async activate(value) {
        this.seatIdentifier = value.value;
        console.table(this.seatIdentifier)
        this.config = value.config;
        this.context = value.contextId;
        this.model = value.modelId;
        this.loading = true;

        this.seatMaps = await this.loadSeatMaps();

        this.currentSeatMap = this.seatMaps[0];
        await this.loadSeatMapChoices(0);
        this.signaler.signal('updated');

        this.loading = false;

    }

    async loadSeatMaps() {
        return await this.client.get(`tourism-seat-map/seat-maps/${this.model}/${this.context}`);
    }

    async loadSeatMapChoices(index) {
        this.loading = true;
        this.seatIdentifier = this.config.value ?? '';
        this.currentSeatMap = this.seatMaps[index];
        const assignments = this.currentSeatMap.map;
        await this.processAssignments(assignments.assignments);
        await this.processRows(assignments);

        this.loading = false;
    }

    hasRowSeats(row) {
        let rowSeats = row?.seats;

        let hasRows = false;

        rowSeats.forEach((seat) => {
            hasRows = typeof seat.number === 'string'

            if (hasRows) {
                return;
            }
        });

        return hasRows;
    }

    async processRows(assignments) {
        this.assignmentsRows = assignments.rows;
    }

    async processAssignments(assignments) {
        assignments.forEach((assignment) => {
            let rowId = assignment?.assignment?.rowId ?? null;
            let seatId = assignment?.assignment?.seatId ?? null;

            assignment.assignmentIdentifier = assignment.participant.id;
            assignment.seatIdentifier = null;

            if (rowId !== null && seatId !== null) {
                assignment.seatIdentifier = this.currentSeatMap.id + '-' + rowId + '-' + seatId;
            }

        });
        this.assignments = assignments;
    }

    onSeatChange(seatIdentifier) {
        this.seatIdentifier = seatIdentifier;
    }

    _getSeatType(type) {
        return type ?? 'hidden';
    }

    getId(currentSeatMap, row, seat)
    {
        return currentSeatMap.id + '-' + row.id + '-' + seat.id;
    }

    disabled(id, type)
    {
        let choices = this.config.choices ?? [];

        if (!Array.isArray(choices)) {
            choices = Object.values(choices);
        }

        return type !== 'normal' || !choices.map(choice => choice['value']).includes(id);
    }

    async ok() {
        await this.controller.ok({
            seatIdentifier: this.seatIdentifier
        });
    }
}
